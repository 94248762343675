






import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Forbidden',
})
export default class extends Vue {}
